.form input {
  margin: 0.5rem 0;
  width: 80%;
  border: none;
  border-bottom: 3px solid rgb(94, 65, 49);
  background-color: #ba8874;
  color: white;
  height: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.form input::placeholder {
  color: rgb(204, 204, 204);
}

.form input:focus {
  outline: none;
  border-bottom: 5px solid rgb(65, 47, 0);
}

.login {
  width: fit-content;
  border-radius: 7px;
  background-color: rgb(94, 65, 49);
  color: white;
  padding: 0.5rem 1rem;
  margin: 1rem auto;
  cursor: pointer;
}

.login:hover {
  background-color: rgb(124, 86, 65);
}
