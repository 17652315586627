.list {
    color: white;
}

.heading {
    padding: 0;
    margin: 0 0 2rem 0;
    font-family: "Playfair Display", serif;
    font-weight: normal;
    font-size: 1.7rem;
  }

  .list_button {
    width: fit-content;
    border-radius: 7px;
    background-color: rgb(94, 65, 49);
    color: white;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    cursor: pointer;
  }