.names {
    font-family: "Playfair Display", serif;
    font-size: 1.2rem;
  }
  
  .and {
    font-size: 1.5rem;
    font-family: "Playfair Display", serif;
  }
  
  .text {
    margin: 2rem 0;
  }
  
  .date {
    display: flex;
  }
  
  .weddingDay {
    border-left: 2px solid white;
    border-right: 2px solid white;
    font-size: 2rem;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    font-family: "Playfair Display", serif;
  }
  
  .weddingYear {
    flex: 1;
    text-align: left;
    margin: auto;
  }
  
  .weddingMonth {
    flex: 1;
    text-align: right;
    margin: auto;
  }
  
  .details {
    padding: 1rem 0;
  }
  
  .time {
    padding: 0.5rem 0;
    font-size: 0.9rem;
  }
  
  .location {
    text-align: center;
    margin: 2rem 0;
  }
  