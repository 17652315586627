.heading {
  padding: 0;
  margin: 0 0 2rem 0;
  font-family: "Playfair Display", serif;
  font-weight: normal;
  font-size: 1.7rem;
}

.form input,
.form select {
  margin: 0.5rem 0;
  width: 80%;
  border: none;
  border-bottom: 3px solid rgb(94, 65, 49);
  background-color: #ba8874;
  color: white;
  height: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.form input::placeholder {
  color: rgb(204, 204, 204);
}

.form input:focus {
  outline: none;
  border-bottom: 5px solid rgb(65, 47, 0);
}

.guest {
  display: block;
}

/*select {
  margin: 1rem 0;
  width: 80%;
  border: none;
  border-bottom: 3px solid rgb(94, 65, 49);
  background-color: #ba8874;
  height: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
}*/

.partner {
  margin: 1rem 0 2rem 0;
}

input[type="checkbox"] {
  background-color: #ba8874;
  height: 0.8rem;
  width: 2rem;
}

.submit {
  width: fit-content;
  border-radius: 7px;
  background-color: rgb(94, 65, 49);
  color: white;
  padding: 0.5rem 1rem;
  margin: 1rem auto;
  cursor: pointer;
}

.submit:hover {
  background-color: rgb(124, 86, 65);
}
