.menu {
    text-decoration: none;
    padding: 0;
    margin: 0;
    background-color: rgb(131, 90, 68);
    color: white;
    cursor: pointer;
}

.menu_item {
    font-size: 1rem;
    border-bottom: 1px solid rgb(107, 74, 57);
    display: flex;
    padding: 0.5rem 1rem;
}

.menu_item:hover,
.menu_item:active {
    background-color: rgb(92, 64, 49);
}

.menu_item span {
    margin-right: 1rem;
}