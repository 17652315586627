.display {
  background-color: rgb(163, 111, 84);
  text-align: center;
  padding: 1rem 0;
  color: white;
  display: flex;
  width: 100%;
}

.cushion {
  flex: 1;
  color: rgb(163, 111, 84);
}

.cover {
  width: 90%;
  max-width: 25rem;
  border-radius: 20px;
  background-color: #ba8874;
  margin: 0 1rem;
  padding: 2rem 2rem;
}