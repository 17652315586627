.header {
  background-color: rgb(163, 111, 84);
  /*background-color: white;*/
  text-align: center;
  padding-bottom: 1rem;
}

.logo {
  width: 10rem;
  margin: 0rem auto;
  padding: 1rem 0;
}

.logo img {
  width: 100%;
}

.menu {
  width: fit-content;
  border-radius: 7px;
  background-color: rgb(94, 65, 49);
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
}

.menu_showing {
  background-color: rgb(124, 86, 65);
  width: fit-content;
  border-radius: 7px;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
}

.menu:hover {
  background-color: rgb(124, 86, 65);
}

.menu_showing:hover {
    background-color: rgb(94, 65, 49);
  }

.menu span {
  margin-right: 1rem;
}

.menu_showing span {
    margin-right: 1rem;
  }
