.footer {
  background-color: rgb(163, 111, 84);
  text-align: center;
  padding: 1rem 0 3rem 0;
}

.RSVP {
  width: fit-content;
  border-radius: 7px;
  background-color: rgb(94, 65, 49);
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  cursor: pointer;
}

.RSVP:hover {
  background-color: rgb(124, 86, 65);
}

.picture {
  background-color: rgb(68, 68, 68);
  margin-top: 1rem;
  height: 15rem;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem auto;
}

.picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.countdown {
  display: flex;
}

.cushion {
  flex: 1;
  color: rgb(163, 111, 84);
}

.counter {
  display: flex;
}

.timer_parts {
  font-size: 1.2rem;
  margin: 0.5rem 0.5rem;
  color: rgb(59, 41, 32);
}

.timer_digits {
  font-size: 2rem;
  font-weight: 600;
}

.timer_labels {
  color: rgb(68, 68, 68);
}
