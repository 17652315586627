.display {
    background-color: rgb(163, 111, 84);
    text-align: center;
    padding: 1rem 0;
    color: white;
    display: flex;
  }
  
  .cushion {
    flex: 1;
    color: rgb(163, 111, 84);
  }
  
  .cover {
    width: 75%;
    max-width: 25rem;
    border-radius: 20px;
    background-color: #ba8874;
    margin: 0 3rem;
    padding: 1rem;
  }
  
  .shops {
    margin: 0.5rem 0;
  }
  
  .cover h1 {
    padding: 0;
    margin: 0;
    font-family: "Playfair Display", serif;
    font-weight: normal;
    font-size: 1.7rem;
  }
  
  .honeymoon {
    display: flex;
  }
  
  .labels {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: right;
  }
  
  .separator {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin: 0 0.7rem;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
  }
  