.heading {
    padding: 0;
    margin: 0 0 2rem 0;
    font-family: "Playfair Display", serif;
    font-weight: normal;
    font-size: 1.7rem;
  }
  
  .map {
    width:100%;
    margin: 0 auto 0 auto;
  }
  